#video-main {
  cursor: url("/assets/images/pointer-video.png"), pointer;
  /* Vimeo iframe overlay, to grab click on the video */
}
#video-main::after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background-color: transparent;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateZ(1px);
}
/*# sourceMappingURL=data:application/json;charset=utf8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImFzc2V0cy9zdHlsZXMvc2Nzcy92aWRlby5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUNBO0VBSUk7QUFNQTs7QUFDQTtFQUNJO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQSIsImZpbGUiOiJhc3NldHMvc3R5bGVzL3Njc3MvdmlkZW8uY3NzIiwic291cmNlc0NvbnRlbnQiOlsiXG4jdmlkZW8tbWFpbiB7XG4gICAgLy90cmFuc2Zvcm06IHNjYWxlKC44KSB0cmFuc2xhdGVZKC0zMCUpO1xuICAgIC8vdHJhbnNpdGlvbjogdHJhbnNmb3JtIDFzIGN1YmljLWJlemllciguMTksMSwuMjIsMSk7XG4gICAgLy9jdXJzb3I6IHBvaW50ZXI7XG4gICAgY3Vyc29yOiB1cmwoJy9hc3NldHMvaW1hZ2VzL3BvaW50ZXItdmlkZW8ucG5nJyksIHBvaW50ZXI7XG5cbiAgICAvL0BtZWRpYSAobWF4LXdpZHRoOiA2MzlweCkge1xuICAgICAgICAvL3RyYW5zZm9ybTogaW5pdGlhbDtcbiAgICAvL31cblxuICAgIC8qIFZpbWVvIGlmcmFtZSBvdmVybGF5LCB0byBncmFiIGNsaWNrIG9uIHRoZSB2aWRlbyAqL1xuICAgICY6OmFmdGVyIHtcbiAgICAgICAgY29udGVudDogXCJcIjtcbiAgICAgICAgZGlzcGxheTogYmxvY2s7XG4gICAgICAgIHdpZHRoOiAxMDAlO1xuICAgICAgICBoZWlnaHQ6IDEwMCU7XG4gICAgICAgIGJhY2tncm91bmQtY29sb3I6IHRyYW5zcGFyZW50O1xuICAgICAgICBwb3NpdGlvbjogYWJzb2x1dGU7XG4gICAgICAgIHRvcDogMDtcbiAgICAgICAgbGVmdDogMDtcbiAgICAgICAgdHJhbnNmb3JtOiB0cmFuc2xhdGVaKDFweCk7IC8vIGludGVyb3BlcmFiaWxpdHkgd2l0aCBTY3JvbGxNYWdpYyBhbmQgR0FTUFxuICAgIH1cbn1cblxuI3ZpZGVvLW1haW4uYW5pbWF0ZSB7XG4gICAgLy90cmFuc2Zvcm06IGluaXRpYWw7XG59XG5cbiJdfQ== */
