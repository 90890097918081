
#video-main {
    //transform: scale(.8) translateY(-30%);
    //transition: transform 1s cubic-bezier(.19,1,.22,1);
    //cursor: pointer;
    cursor: url('/assets/images/pointer-video.png'), pointer;

    //@media (max-width: 639px) {
        //transform: initial;
    //}

    /* Vimeo iframe overlay, to grab click on the video */
    &::after {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        background-color: transparent;
        position: absolute;
        top: 0;
        left: 0;
        transform: translateZ(1px); // interoperability with ScrollMagic and GASP
    }
}

#video-main.animate {
    //transform: initial;
}

