
/* GRAPHIC */
/*
body::before {
    content: "";
    min-width: 100vw;
    min-height: 150vh;
    background-image: url('/graphic.png');
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 100% auto;
    display: block;
    position: absolute;
    z-index: -1;
    top: 0;
}
*/
