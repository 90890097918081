@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

/* SCSS START */
/* the location of this files is handled by postcss-import plugin */
@import "fonts.css";
@import "news-marquee.css";
@import "dev.css";
@import "video.css";
@import "page-local-cats-petreet.css";
/* SCSS END */

:root {
    --brown: #4e4b43;
    --brown-light: #e5dccc;
    --yellow: #f2f94b;
    --green: #aad402;
}

@layer utilities {

    @variants responsive {

        /* ref: https://css-tricks.com/adding-stroke-to-web-text/ */
        .text-stroke-green {
            --p:  .005em;
            --q: -.005em;
            text-shadow:
            var(--q) var(--q) 0 var(--green),
            var(--p) var(--q) 0 var(--green),
            var(--q) var(--p) 0 var(--green),
            var(--p) var(--p) 0 var(--green);
            -webkit-text-stroke: .015em var(--green);
            text-stroke: .015em var(--green);
        }

    }

}

#button-mailto::before {
    content: "";
    display: block;
    width: 108%;
    height: 120%;
    top: -10%;
    left: -4%;
    background-color: transparent;
    border: .15em var(--yellow) solid;
    border-radius: 9999px;
    position: absolute;
}

