$screen-2xl: 1535px;
$screen-xl: 1279px;
$screen-lg: 1023px;
$screen-md: 767px;
$screen-sm: 639px;

#page-lcp-title {
    font-size: max(4vw, 1rem);
    line-height: 1;

    @media screen and (max-width: $screen-lg) {
        font-size: 6vw;
    }
}

#page-lcp-description {
    font-size: max(1.10vw, 8pt);
    line-height: 1.3;

    @media screen and (max-width: $screen-lg) {
        font-size: 1rem;
    }
}

#page-lcp-image-anvedi {
    width: 54.4%;

    @media screen and (max-width: $screen-lg) {
        width: 100%;
    }
}

