
@font-face {
    font-family: 'Goudy Heavyface';
    src: url('/assets/fonts/Goudy-Heavyface/Goudy-Heavyface.ttf'),
         url('/assets/fonts/Goudy-Heavyface/Goudy-Heavyface.woff') format('woff');
}

$SourceSansProTypes: (
    ( SourceSansPro-ExtraLight       , 200    , normal ) ,
    ( SourceSansPro-ExtraLightItalic , 200    , italic ) ,
    ( SourceSansPro-Light            , 300    , normal ) ,
    ( SourceSansPro-LightItalic      , 300    , italic ) ,
    ( SourceSansPro-Regular          , normal , normal ) ,
    ( SourceSansPro-Italic           , normal , italic ) ,
    ( SourceSansPro-SemiBold         , 600    , normal ) ,
    ( SourceSansPro-SemiBoldItalic   , 600    , italic ) ,
    ( SourceSansPro-Bold             , 700    , normal ) ,
    ( SourceSansPro-BoldItalic       , 700    , italic ) ,
    ( SourceSansPro-Black            , 900    , normal ) ,
    ( SourceSansPro-BlackItalic      , 900    , italic ) ,
);

@each $fontType in $SourceSansProTypes {
    @font-face {
        font-family: 'SourceSansPro';
        src: url('/assets/fonts/SourceSansPro/#{nth($fontType, 1)}.ttf'),
             url('/assets/fonts/SourceSansPro/#{nth($fontType, 1)}.woff') format('woff');
        font-weight: nth($fontType, 2);
        font-style: nth($fontType, 3);
    }
}

