#page-lcp-title {
  font-size: max(4vw, 1rem);
  line-height: 1;
}
@media screen and (max-width: 1023px) {
  #page-lcp-title {
    font-size: 6vw;
  }
}

#page-lcp-description {
  font-size: max(1.10vw, 8pt);
  line-height: 1.3;
}
@media screen and (max-width: 1023px) {
  #page-lcp-description {
    font-size: 1rem;
  }
}

#page-lcp-image-anvedi {
  width: 54.4%;
}
@media screen and (max-width: 1023px) {
  #page-lcp-image-anvedi {
    width: 100%;
  }
}
/*# sourceMappingURL=data:application/json;charset=utf8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImFzc2V0cy9zdHlsZXMvc2Nzcy9wYWdlLWxvY2FsLWNhdHMtcGV0cmVldC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQU1BO0VBQ0k7RUFDQTs7QUFFQTtFQUpKO0lBS1E7Ozs7QUFJUjtFQUNJO0VBQ0E7O0FBRUE7RUFKSjtJQUtROzs7O0FBSVI7RUFDSTs7QUFFQTtFQUhKO0lBSVEiLCJmaWxlIjoiYXNzZXRzL3N0eWxlcy9zY3NzL3BhZ2UtbG9jYWwtY2F0cy1wZXRyZWV0LmNzcyIsInNvdXJjZXNDb250ZW50IjpbIiRzY3JlZW4tMnhsOiAxNTM1cHg7XG4kc2NyZWVuLXhsOiAxMjc5cHg7XG4kc2NyZWVuLWxnOiAxMDIzcHg7XG4kc2NyZWVuLW1kOiA3NjdweDtcbiRzY3JlZW4tc206IDYzOXB4O1xuXG4jcGFnZS1sY3AtdGl0bGUge1xuICAgIGZvbnQtc2l6ZTogbWF4KDR2dywgMXJlbSk7XG4gICAgbGluZS1oZWlnaHQ6IDE7XG5cbiAgICBAbWVkaWEgc2NyZWVuIGFuZCAobWF4LXdpZHRoOiAkc2NyZWVuLWxnKSB7XG4gICAgICAgIGZvbnQtc2l6ZTogNnZ3O1xuICAgIH1cbn1cblxuI3BhZ2UtbGNwLWRlc2NyaXB0aW9uIHtcbiAgICBmb250LXNpemU6IG1heCgxLjEwdncsIDhwdCk7XG4gICAgbGluZS1oZWlnaHQ6IDEuMztcblxuICAgIEBtZWRpYSBzY3JlZW4gYW5kIChtYXgtd2lkdGg6ICRzY3JlZW4tbGcpIHtcbiAgICAgICAgZm9udC1zaXplOiAxcmVtO1xuICAgIH1cbn1cblxuI3BhZ2UtbGNwLWltYWdlLWFudmVkaSB7XG4gICAgd2lkdGg6IDU0LjQlO1xuXG4gICAgQG1lZGlhIHNjcmVlbiBhbmQgKG1heC13aWR0aDogJHNjcmVlbi1sZykge1xuICAgICAgICB3aWR0aDogMTAwJTtcbiAgICB9XG59XG5cbiJdfQ== */
