@keyframes marquee {
    0% { transform: translateX(100vw); }
    100% { transform: translateX(-100%); }
}

#news-marquee {
    background: var(--brown-light);
    color: var(--brown);
    padding: .2em;
    overflow: hidden;
    font-size: 8px;

    .news-container {
        white-space: nowrap;
        display: inline-block;
        animation: marquee 55s linear infinite;

        &:hover {
            animation-play-state: paused;
        }
    }

    .news-item {
        display: inline-block;
        font-size: 5.6em;
        font-weight: lighter;
        white-space: nowrap;

        &::after {
            content: ' ';
            display: inline-block;
            width: 1em;
            height: 1em;
            vertical-align: sub;
            background-image: url('/assets/images/lampi-lightning.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
        }

        /*
        &:last-of-type {
            color: var(--brown);
        }
        */
    }
}

